/*
https://javisperez.github.io/tailwindcolorshades/?sj-blue=3499cc&sj-purple=666699&sj-green=6fbe44

colors: {
  'sj-blue': {
    '50': '#f5fafc',
    '100': '#ebf5fa',
    '200': '#cce6f2',
    '300': '#aed6eb',
    '400': '#71b8db',
    '500': '#3499cc',
    '600': '#2f8ab8',
    '700': '#277399',
    '800': '#1f5c7a',
    '900': '#194b64'
  },
  'sj-purple': {
    '50': '#f7f7fa',
    '100': '#f0f0f5',
    '200': '#d9d9e6',
    '300': '#c2c2d6',
    '400': '#9494b8',
    '500': '#666699',
    '600': '#5c5c8a',
    '700': '#4d4d73',
    '800': '#3d3d5c',
    '900': '#32324b'
  },
  'sj-green': {
    '50': '#f8fcf6',
    '100': '#f1f9ec',
    '200': '#dbefd0',
    '300': '#c5e5b4',
    '400': '#9ad27c',
    '500': '#6fbe44',
    '600': '#64ab3d',
    '700': '#538f33',
    '800': '#437229',
    '900': '#365d21'
  }
}, */

html {
  /* Fast click on mobile devices (avoid 300ms delay) */
  /* https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away */
  touch-action: manipulation;
}

/* This class is used to prevent long-press context menu on iOS devices */
.prevent-long-press {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

abbr {
  cursor: help;
}

/* Better style for disabled inputs */
.select select[disabled], [disabled].textarea, [disabled].input, fieldset[disabled] .select select, .select fieldset[disabled] select, fieldset[disabled] .textarea, fieldset[disabled] .input {
  border-color: #e0e0e0 !important;
  color: #ccc !important;
  opacity: 1 !important; /* needed for iOS */
}

.unstyled-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none;
}

.show-title-on-hover,
abbr[title] {
  position: relative;
}

abbr[title] {
  /* ensure consistent styling across browsers */
  text-decoration: underline dotted;
}


.show-title-on-hover::after,
.show-title-on-hover::after,
abbr[title]::after,
abbr[title]::after {
  content: attr(title);
  opacity: 0;
  transition: opacity 250ms 200ms;

  /* position tooltip like the native one */
  position: absolute;
  left: 0;
  bottom: 20%;
  margin: -2rem 1rem 1rem;
  white-space: normal;
  text-align: left;

  /* style tooltip */
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  z-index: 1;
  pointer-events: none;
}

.show-title-on-hover:hover::after,
.show-title-on-hover:focus::after,
abbr[title]:hover::after,
abbr[title]:focus::after {
  opacity: 1;
  transition: opacity 150ms 500ms;
}

body .content h1 { font-size: 1.6rem; }
body .content h2 { font-size: 1.4rem; }
body .content h3 { font-size: 1.2rem; }
body .content h4 { font-size: 1.0rem; }

.content table {
  width: auto !important;
}

/* Avoid blue highlight when tapping UI on mobile devices */
div, a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.icon-button {
  height: 2rem;
  width: 2rem;
  line-height: 0rem;
  font-size: 0rem;
}

.icon-button svg {
  height: 1rem;
  width: 1rem;
}

.icon-button.button.is-inverted:not(:hover) {
  border-color: transparent;
  box-shadow: none;
}

.icon-button.button.is-inverted:hover {
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%) !important;
}

.button.tiny {
  padding: 0rem 0.1rem;
  line-height: 0.5rem;
  font-size: 0.5rem;
}

.button.tiny svg {
  width: 1rem;
  height: 1rem;
}

.shadow,
.button {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important;
}

.shadow-inset {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2) !important;
}

.shadow-inset-lg {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2) !important;
}

.shadow-inset-xl {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5) !important;
}


.shadow-top {
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3) !important;
}

.shadow-xl {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5) !important;
}

.shadow-2xl {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.6) !important;
}

.no-shadow {
  box-shadow: none !important;
}

.button.is-inverted.no-shadow:hover {
  box-shadow: none !important;
}

.no-border {
  border: none !important;
}

.no-border-top {
  border-top: none !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.no-border-left {
  border-left: none !important;
}

.no-border-right {
  border-right: none !important;
}

.button.is-inverted {
  box-shadow: none !important;
}

.backstage a:hover {
  text-decoration: underline;
}

.backstage.section {
  padding-bottom: 6rem;
}

@media only screen and (max-width: 600px) {
  .backstage .section {
    padding: 1rem;
    padding-bottom: 6rem;
  }
  .backstage nav {
    padding-bottom: 1rem;
  }
}

.gray-background {
  background-color: #f0f2f5;
}

body hr {
  height: 1px;
  background-color: #eee !important;
}

.gray-background hr {
  background-color: #ddd;
}

* {
  box-sizing: border-box;
}

.future {
  display: none !important;
  transform: rotate(-3deg);
  opacity: 0.9;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 0.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: inherit;
  box-shadow: 0 1px 5px rgba(0,0,0,0.2);
}

.future::before {
  content: 'In the (not too distant) future...';
  display: block;
  margin-top: -3rem;
  margin-bottom: 1.5rem;
  margin-left: -0.9rem;
  color: #99c;
  font-style: italic;
}

/* Override the opacity of Bulma modal  */
.modal-background {
  opacity: 0.5;
}

ol li {
  margin: 1rem 0;
  margin-left: 1.5rem;
}

.button.is-primary {
  background-color: #3499CC !important;
}

.flash {
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.flash.is-green {
  background-color: #c8ffab
}

.button.is-green  { background-color: #6FBE44 !important; }
.button.is-blue   { background-color: #3499CC !important; }
.button.is-purple { background-color: #666699 !important; }


.black          { color: #000 }
.light-gray     { color: #CCCCCC }
.lightgray      { color: #CCCCCC }
.almost-white   { color: #DDDDDD }
.gray           { color: #999999 }
.dark-gray      { color: #666666 }
.darkgray       { color: #666666 }
.green          { color: #6FBE44 }
.dark-green     { color: #437229 }
.blue           { color: #3499CC }
a:hover.blue    { color: #3499CC }
.purple         { color: #666699 }
.red            { color: #b11e1e }
.orange         { color: #aa7a00 }
.light-purple   { color: #c2c2d6 }
.white          { color: white }
.default-text-color { color: #4a4a4a }
.hover_default-text-color:hover { color: #4a4a4a }

.hover_green:hover { color: #6FBE44 !important }
.hover_red:hover { color: #b11e1e !important }
.hover_gray:hover { color: #999999 !important }
.hover_white:hover { color: white !important }

.bg-modal       { background-color: #00000066 }
.bg-f8f8f8      { background-color: #f8f8f8 }
.bg-f3f3f3      { background-color: #f3f3f3 }
.bg-eee         { background-color: #EEEEEE }
.bg-ddd         { background-color: #DDDDDD }
.bg-ccc,
.bg-light-gray  { background-color: #CCCCCC }
.hover_bg-white:hover  { background-color: white }
.hover_bg-light-gray:hover  { background-color: #DDDDDD }
.hover_bg-very-light-gray:hover  { background-color: #EEEEEE }
.bg-gray        { background-color: #999999 }
.bg-green       { background-color: #6FBE44 }
.bg-dark-green  { background-color: #437229 }
.bg-light-green  { background-color:#e0ffd0 }
.bg-light-purple { background-color:#9F9DCD }
.bg-blue        { background-color: #3499CC }
.bg-light-blue  { background-color: #cbeeff }
.hover_bg-light-blue:hover  { background-color: #cbeeff }
.bg-purple      { background-color: #666699 }
.bg-dark-purple { background-color: #3d3d5c }
.bg-black       { background-color: black }
.bg-white       { background-color: white }
.bg-none        { background-color: transparent }
.bg-orange-transparent { background-color: #ffae0033 }
.bg-dark-red { background-color: rgb(149, 0, 0); }
.bg-red { background-color: #fee; }
.bg-red-transparent { background-color: #ff0000CC }
.bg-light-red-transparent { background-color: #ff000033 }
.hover_bg-light-red-transparent:hover { background-color: #ff000033 }
.bg-black-transparent { background-color: #000000CC }
.bg-blue-transparent { background-color: #3499CC33 }
.bg-white-transparent { background-color: #FFFFFF66 }
.bg-disabled    { background-color: #F6F6F6 }
.bg-yellow      { background-color: #ffa }
.hover_bg-dark-red:hover { background-color: rgb(149, 0, 0); }
.bg-mild-blue  { background-color: #92d4f5 }
.hover_bg-mild-blue:hover  { background-color: #92d4f5 }


.grayscale { filter: saturate(0) brightness(1.1) }
.faded     { filter: saturate(0.3) brightness(1.3) contrast(0.8) }
.blur, .blurry { filter: blur(3px); }
.ui-locked { filter: blur(3px) brightness(0.3); }

.uppercase {
  text-transform: uppercase;
}

.button.is-green,
.button.is-purple,
.button.is-blue {
  color: white;
  border-color: transparent;
  font-weight: bold;
}

.button.is-green:focus,
.button.is-purple:focus,
.button.is-blue:focus,
.button.is-green:hover,
.button.is-purple:hover,
.button.is-blue:hover {
  color: white;
  border-color: transparent;
}

.button:hover {
  filter: brightness(1.1);
}

.button.is-facebook { background-color: #1877F2 !important; }
.button.is-linkedin { background-color: #0A66C2 !important; }
.button.is-twitter { background-color: #000000 !important; }
.button.is-old-twitter { background-color: #1DA1F2 !important; }
.button.is-instagram {
  background: #E23523 !important;
  background: linear-gradient(10deg, #F4BF48 2%,#E23523 20%,#8B3DBA 70%);
}

.social-icon-button {
  padding: 1.3rem 0.75rem !important;
}

.bold, .is-bold {
  font-weight: bold;
}

.hover_bold:hover {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.monospace {
  font-family: monospace;
}

.no-bold,
.not-bold,
.is-not-bold {
  font-weight: normal;
}

.is-green {
  color: #4f932b;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  gap: 0.5rem;
}

.button-container > *:first-child {
  margin-left: auto;
}

.button-container > *:last-child {
  margin-right: auto;
}

.button-container.left-aligned {
  justify-content: flex-start;
}

.nowrap, .no-wrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.pre-wrap {
  white-space: pre-wrap;
}

.wrap-normal {
  white-space: normal;
}

.forcewrap {
  word-break: break-word;
}

.padded {
  padding: 1rem;
}

.spaced {
  margin: 1.5rem 0;
}

.margin-above {
  margin-top: 1rem;
}

.margin-below {
  margin-bottom: 1rem;
}

.double-padded {
  padding: 2rem;
}

.indented {
  padding-left: 1rem;
}

.fit-content {
  width: fit-content;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hover-parent {
  display: none;
}

*:hover > .hover-parent {
  display: block
}

.hover_parent_opacity {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

*:hover > .hover_parent_opacity {
  opacity: 1;
  transition: opacity 300ms ease-out 1s;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.grid {
  display: grid;
}

.grid-2, .grid-2-cols, .grid-cols-2 { grid-template-columns: repeat(2, auto) }
.grid-3, .grid-3-cols, .grid-cols-3 { grid-template-columns: repeat(3, auto) }
.grid-4, .grid-4-cols, .grid-cols-4 { grid-template-columns: repeat(4, auto) }
.grid-5, .grid-5-cols, .grid-cols-5 { grid-template-columns: repeat(5, auto) }
.grid-6, .grid-6-cols, .grid-cols-6 { grid-template-columns: repeat(6, auto) }

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column,
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-centered {
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-justify-start {
  justify-content: start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end,
.flex-justify-right {
  justify-content: end;
}

.justify-flex-end {
  justify-content: flex-end;
}

.flex-start,
.flex-left,
.flex-align-start,
.flex-align-left {
  align-items: start;
}

.flex-end,
.flex-right,
.flex-align-end,
.flex-align-right {
  align-items: end;
}

.flex-align-center {
  align-items: center;
}

.flex-stretch {
  align-items: stretch;
}

.flex-start {
  align-items: start;
}

.flex-end {
  align-items: end;
}

.flex-grow {
  flex: 1 1 0;
}

.flex-same {
  width: 0;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-columns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

@media only screen and (max-width: 600px) {
  .flex-columns {
    flex-direction: column;
  }
}

.spread {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .xs_spread {
    justify-content: space-between;
  }
  .xs_full-width {
    width: 100%;
  }
}

.center-vertically {
  display: flex;
  align-items: center;
}

.text-left,   .left-text,   .left   { text-align: left;   }
.text-center, .center-text, .center { text-align: center; }
.text-right,  .right-text,  .right  { text-align: right;  }

.ellipsis {
  text-overflow: ellipsis;
}

.settings-panel h3, .settings-panel h4 {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.settings h3, .settings h4 {
  margin-top: 1.5rem;
  text-align: left;
}

.settings p {
  margin-top: 0.75rem;
}

.settings-group {
  border: 1px solid #ccc;
  padding: 0.75rem;
  margin-top: -1px;
}

.settings-group a {
  text-decoration: underline;
}

.settings-group .details {
  font-size: 0.9rem;
}

.settings-group a {
  text-decoration: underline;
}

.danger {
  color: rgb(221, 0, 0);
}

.header-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

@media only screen and (max-width: 800px) {
  .header-with-button {
    flex-direction: column;
    align-items: flex-start;
  }
}

.content .header-with-button h2,
.header-with-button h2 {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

.note {
  margin: 1rem 0;
  font-size: 0.9rem;
}

.small {
  font-size: 0.9rem;
}

.tiny {
  font-size: 0.8rem;
}

.subtle {
  color: #888;
}

.very-subtle {
  color: #bbb;
}

.note.is-danger, p.is-danger, span.is-danger, .error-message {
  color: darkred !important;
}

.italic, .italics {
  font-style: italic;
}

.missing {
  color: #aaa;
  font-style: italic;
}

a.missing {
  color: #7f95f7;
  font-style: italic;
}

.cursor-help        { cursor: help }
.cursor-not-allowed { cursor: not-allowed }
.cursor-default     { cursor: default }
.cursor-nesw-resize { cursor: nesw-resize }
.cursor-nwse-resize { cursor: nwse-resize }
.cursor-ns-resize   { cursor: ns-resize }
.cursor-ew-resize   { cursor: ew-resize }

.pointer {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
  transition: box-shadow 300ms ease-out;
}

.clickable:hover {
  box-shadow: 0 0 3px 3px #277dd2cc !important;
  transition: box-shadow 100ms ease-out;
}

.strike, .strikethrough, .line-through {
  text-decoration: line-through;
}

.link, .underlined, .underline {
  color: #2f8ab8;
  text-decoration: underline;
}

.button-link {
  color: #2f8ab8;
  border: none;
  padding: 0;
  background: none;
  font-size: unset;
}

.no-underline {
  text-decoration: none !important;
}

.hover_link:hover,
.hover_underline:hover,
.hover_underlined:hover {
  color: #2f8ab8;
  text-decoration: underline;
}

.hover_link.white:hover,
.hover_underline.white:hover,
.hover_underlined.white:hover {
  color: white;
  text-decoration: underline;
}

.subtle-link {
  color: #2f8ab8;
}

.subtle-link:hover {
  color: #2f8ab8;
  text-decoration: underline;
}

.link.white {
  color: white !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.full { width: 100%; height: 100% }

.w-full,   .width-full,    .full-width    { width:  100%; }
.h-full,   .height-full,   .full-height   { height: 100%; }
.w-screen, .width-screen,  .screen-width  { width:  100vw; }
.h-screen, .height-screen, .screen-height { height: 100vh; }

.min-w-screen, .min-width-screen,  .min-screen-width  { min-width:  100vw; }
.min-h-screen, .min-height-screen, .min-screen-height { min-height: 100vh; }


.min-width-full,  .min-full-width,  .min-w-full { min-width:  100%; }
.min-height-full, .min-full-height, .min-h-full { min-height: 100%; }
.max-width-full,  .max-full-width,  .max-w-full { max-width:  100%; }
.max-height-full, .max-full-height, .max-h-full { max-height: 100%; }
.min-width-300 { min-width: 300px }
.max-width-300 { max-width: 300px }
.max-width-320 { max-width: 320px }
.max-width-350 { max-width: 350px }
.max-width-400 { max-width: 400px }
.max-width-450 { max-width: 450px }
.max-width-800 { max-width: 800px }

.delete-button-with-icon {
  cursor: pointer;
  color: red;
  opacity: 0.6;
  padding: 8px 12px;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0.9rem;
  font-weight: bold;
  font-style: normal;
  border: 1px solid #ff000033;
  background: #ff000011;
  display: inline-block;
}
.delete-button-with-icon:hover {
  background-color: red;
}
.delete-button-with-icon:hover .icon {
  color: white;
}

.delete-button-with-icon.disabled {
  cursor: not-allowed;
  color: #ccc !important;
  background-color: #eee;
  border-color: #ccc;
  opacity: 0.3;
}
.delete-button-with-icon.disabled:hover .icon {
  color: inherit;
}

@media print {
  .nonprintable {
    display: none;
  }
}

.debug {
  position: fixed;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  top: 0;
  left: 0;
  max-height: 100%;
  margin: 1rem;
  z-index: 999999;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  white-space: pre-line;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.popup-item {
  padding: 0.4rem 0.8rem;
  border: none;
  background-color: white;
  font-size: unset;
  color: #333;
  text-align: left;
  border-radius: 0.25rem;
}

.popup-item:disabled {
  color: #ccc;
}

.popup-item:last-child {
  border-bottom: none;
}

a.popup-item {
  color: unset;
}

.popup-item:hover {
  background-color: #3499CC;
  color: white;
}

.popup-item:disabled:hover {
  background-color: inherit;
  color: #ccc;
}

.popup-divider {
  border-top: 1px solid #ddd;
  margin: 0.25rem 0.75rem;
}

.invisible {
  visibility: hidden;
}

.loader {
  border-color: #3499CC;
  border-right-color: transparent;
  border-top-color: transparent;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: -2px;
}


.relative { position: relative; }
.fixed { position: fixed; }
.absolute { position: absolute; }
.sticky { position: sticky; }

.top,    .position-top     { top:    0; }
.bottom, .position-bottom  { bottom: 0; }
.left,   .position-left    { left:   0; }
.right,  .position-right   { right:  0; }

.absolute-full,
.absolute-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.z-down { z-index: -1;}
.z-up,
.z-1 { z-index: 1; }
.z-2 { z-index: 2; }
.z-3 { z-index: 3; }
.z-4 { z-index: 4; }
.z-5 { z-index: 5; }

.m-auto,
.margin-auto {
  margin: auto;
}

.w-1 { width:  0.25rem; }
.h-1 { height: 0.25rem; }
.w-2 { width:  0.50rem; }
.h-2 { height: 0.50rem; }
.w-4 { width:  1.00rem; }
.h-4 { height: 1.00rem; }
.w-6 { width:  1.50rem; }
.h-6 { height: 1.50rem; }

/* Heroicons switch from 'w-6 h-6' to 'size-6' */
.size-6 { width:  1.50rem; height: 1.50rem; }

/* These are already included in bulma
.px-1 { padding-left:  0.25rem; padding-right:  0.25rem; }
.py-1 { padding-top:   0.25rem; padding-bottom: 0.25rem; }
.px-2 { padding-left:  0.50rem; padding-right:  0.50rem; }
.py-2 { padding-top:   0.50rem; padding-bottom: 0.50rem; }
.px-4 { padding-left:  1.00rem; padding-right:  1.00rem; }
.py-4 { padding-top:   1.00rem; padding-bottom: 1.00rem; }
.px-6 { padding-left:  1.50rem; padding-right:  1.50rem; }
.py-6 { padding-top:   1.50rem; padding-bottom: 1.50rem; }

.p-1 { padding:  0.25rem; }
.p-2 { padding:  0.50rem; }
.p-4 { padding:  1.00rem; }
.p-6 { padding:  1.50rem; }

.px-1 { padding-left:  0.25rem; padding-right:  0.25rem; }
.py-1 { padding-top:   0.25rem; padding-bottom: 0.25rem; }
.px-2 { padding-left:  0.50rem; padding-right:  0.50rem; }
.py-2 { padding-top:   0.50rem; padding-bottom: 0.50rem; }
.px-4 { padding-left:  1.00rem; padding-right:  1.00rem; }
.py-4 { padding-top:   1.00rem; padding-bottom: 1.00rem; }
.px-6 { padding-left:  1.50rem; padding-right:  1.50rem; }
.py-6 { padding-top:   1.50rem; padding-bottom: 1.50rem; }

.p-1 { padding:  0.25rem; }
.p-2 { padding:  0.50rem; }
.p-4 { padding:  1.00rem; }
.p-6 { padding:  1.50rem; }
*/


.-m-1 { margin:  -0.25rem; }
.-m-2 { margin:  -0.50rem; }
.-m-4 { margin:  -1.00rem; }
.-m-6 { margin:  -1.50rem; }

.text-2xs  { font-size: 0.7rem;   line-height: 0.8rem; }
.text-xs   { font-size: 0.75rem;  line-height: 1rem;    }
.text-sm   { font-size: 0.875rem; line-height: 1.25rem; }
.text-base { font-size: 1rem;     line-height: 1.5rem;  }
.text-lg   { font-size: 1.125rem; line-height: 1.75rem; }
.text-xl   { font-size: 1.25rem;  line-height: 1.75rem; }
.text-2xl  { font-size: 1.5rem;   line-height: 2rem;    }
.text-3xl  { font-size: 1.875rem; line-height: 2.25rem; }
.text-4xl  { font-size: 2.25rem;  line-height: 2.5rem;  }
.text-5xl  { font-size: 3rem;     line-height: 1; }
.text-6xl  { font-size: 3.75rem;  line-height: 1; }
.text-7xl  { font-size: 4.5rem;   line-height: 1; }
.text-8xl  { font-size: 6rem;     line-height: 1; }
.text-9xl  { font-size: 8rem;     line-height: 1; }

.text-15px { font-size: 15px;     line-height: 1rem;}


.text-sm svg {
  width: 1.2rem;
  height: 1.2rem;
}

.text-xs svg {
  width: 1rem;
  height: 1rem;
}


@media screen and (min-width: 640px) {
  .sm_text-base { font-size: 1rem; line-height: 1.5rem;  }
  .sm_text-lg   { font-size: 1.125rem; line-height: 1.75rem; }
  .sm_text-xl   { font-size: 1.25rem;  line-height: 1.75rem; }
}

@media screen and (min-width: 321px) {
  .xs_text-base { font-size: 1rem; line-height: 1.5rem;  }
  .xs_text-xl   { font-size: 1.25rem;  line-height: 1.75rem; }
}


.p-1px { padding: 1px; }
.m-1px { margin:  1px; }
.gap-1px { gap: 1px; }

.mt--125 { margin-top:  -0.125rem; }
.mt--1px { margin-top:  -1px; }
.mt--1 { margin-top:  -0.25rem; }
.mt--2 { margin-top:  -0.5rem; }
.mr--1 { margin-right:  -0.25rem; }
.mr--2 { margin-right:  -0.5rem; }

.gap-0 { gap: 0.00rem; }
.gap-1 { gap: 0.25rem; }
.gap-2 { gap: 0.50rem; }
.gap-3 { gap: 0.75rem; }
.gap-4 { gap: 1.00rem; }
.gap-5 { gap: 1.25rem; }
.gap-6 { gap: 1.50rem; }
.gap-7 { gap: 1.75rem; }
.gap-8 { gap: 2.00rem; }
.gap-9 { gap: 2.25rem; }
.gap-10 { gap: 2.50rem; }

.gap-row-0  { row-gap: 0.00rem; }
.gap-row-1  { row-gap: 0.25rem; }
.gap-row-2  { row-gap: 0.50rem; }
.gap-row-3  { row-gap: 0.75rem; }
.gap-row-4  { row-gap: 1.00rem; }
.gap-row-5  { row-gap: 1.25rem; }
.gap-row-6  { row-gap: 1.50rem; }
.gap-row-7  { row-gap: 1.75rem; }
.gap-row-8  { row-gap: 2.00rem; }
.gap-row-9  { row-gap: 2.25rem; }
.gap-row-10 { row-gap: 2.50rem; }

.gap-col-0 ,  .gap-column-0  { column-gap: 0.00rem; }
.gap-col-1 ,  .gap-column-1  { column-gap: 0.25rem; }
.gap-col-2 ,  .gap-column-2  { column-gap: 0.50rem; }
.gap-col-3 ,  .gap-column-3  { column-gap: 0.75rem; }
.gap-col-4 ,  .gap-column-4  { column-gap: 1.00rem; }
.gap-col-5 ,  .gap-column-5  { column-gap: 1.25rem; }
.gap-col-6 ,  .gap-column-6  { column-gap: 1.50rem; }
.gap-col-7 ,  .gap-column-7  { column-gap: 1.75rem; }
.gap-col-8 ,  .gap-column-8  { column-gap: 2.00rem; }
.gap-col-9 ,  .gap-column-9  { column-gap: 2.25rem; }
.gap-col-10,  .gap-column-10 { column-gap: 2.50rem; }


.opacity-0 {opacity: 0;}
.opacity-10 {opacity: 0.1;}
.opacity-20 {opacity: 0.2;}
.opacity-30 {opacity: 0.3;}
.opacity-40 {opacity: 0.4;}
.opacity-50 {opacity: 0.5;}
.opacity-60 {opacity: 0.6;}
.opacity-70 {opacity: 0.7;}
.opacity-80 {opacity: 0.8;}
.opacity-90 {opacity: 0.9;}
.opacity-95 {opacity: 0.95;}
.opacity-100 {opacity: 1;}
.hover_opacity-100:hover {opacity: 1;}

.debug-red   { border: 4px dotted red   !important; }
.debug-green { border: 4px dotted green !important; }
.debug-blue  { border: 4px dotted blue  !important; }

.border { border: 1px solid #eee }
.border-top { border-top: 1px solid #eee }
.border-left { border-left: 1px solid #eee }
.border-bottom { border-bottom: 1px solid #eee }
.border-white { border-color: white }
.border-width-1 { border-width: 1px;}
.border-sj-purple-600 { border: 1px solid #5c5c8a }
.border-gray { border: 1px solid #d0d0d0; }
.border-dark-gray { border: 1px solid #ccc; }
.border-darker-gray { border: 1px solid #aaa; }
.border-333 { border: 1px solid #333; }
.border-black { border: 1px solid black; }
.hover_border-gray:hover { border: 1px solid #d0d0d0; }
.border-red { border: 1px solid #fbb; }
.border-orange { border: 1px solid #aa7a0033; }
.border-green { border: 1px solid #9fe679; }
.border-purple { border: 1px solid #666699; }
.border-light-purple { border: 1px solid #66669933; }
.hover_border-red { border: 1px solid #fbb !important; }
.hover_bg-red:hover { background-color: #fee !important; }
.hover_bg-redder:hover { background-color: #bc8181 !important; }
.border-yellow { border: 1px solid #dda; }
.border-blue    { border-color: #3499CC66; }
.border-width-2 { border-width: 2px;}

.rotate-90 { transform: rotate(90deg); }
.rotate-180 { transform: rotate(180deg); }
.rotate-270 { transform: rotate(270deg); }

.rounded    { border-radius: 4px; }
.rounded-lg { border-radius: 0.5rem; }
.rounded-xl { border-radius: 0.75rem; }
.rounded-50 { border-radius: 50%; }
.rounded-full { border-radius: 100%; }

.overflow-hidden    { overflow: hidden }
.overflow-hidden-x  { overflow-x: hidden }
.overflow-auto-x    { overflow-x: auto }
.overflow-auto-y    { overflow-y: auto }
.overflow-scroll-y  { overflow-y: scroll }

.transition      { transition: all 150ms; }
.transition-slow { transition: all 500ms; }

.resize-none { resize: none }
.hidden { display: none; }

@media screen and (min-width: 640px) {
  .sm_inline {
    display: inline;
  }
}

.sm_contents { display: none; }

@media screen and (min-width: 640px) {
  .sm_hidden    { display: none; }
  .sm_contents  { display: contents; }
  .sm_flex      { display: flex }
  .sm_flex-row  { flex-direction: row; }
  .sm_flex-col  { flex-direction: column; }
  .sm_flex-align-center { align-items: center; }
  .sm_p-4       { padding: 1rem; }
  .sm_p-5       { padding: 1.25rem !important; }
  .sm_px-2      { padding-left: 0.5rem !important; padding-right: 0.5rem !important; }
  .sm_py-1      { padding-top: 0.25rem; padding-bottom: 0.25rem; }
  .sm_rounded-lg { border-radius: 0.5rem; }
  .sm_full-width { width: 100%; }
  .sm_flex-centered {
    align-items: center;
    justify-content: center;
  }
}

.md_contents { display: none; }

@media screen and (min-width: 900px) {
  .md_hidden   { display: none; }
  .md_contents { display: contents; }
  .md_flex-col { display: flex; flex-direction: column; }
}

@media screen and (min-width: 1024px) {
  .lg_flex-row { display: flex; flex-direction: row; }
}

.truncate-placeholder::placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-container {
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: top;
  opacity: 0;
  transition: opacity 250ms 200ms;
  z-index: 1;
}

*:hover > .tooltip-container {
  opacity: 1;
  transition: opacity 250ms 400ms;
}

.tooltip {
  white-space: nowrap;

  position: absolute;
  left: 0;
  text-align: left;
  margin-left: 1.5rem;
  opacity: 0.9;

  /* style tooltip */
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  z-index: 1;
  pointer-events: none;
  overflow: visible;
}

.hover-text {
  /* position tooltip like the native one */
  position: absolute;
  left: 0;
  bottom: 20%;
  margin: -2rem 1rem 1rem;
  white-space: normal;
  text-align: left;

  /* style tooltip */
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  z-index: 1;
  pointer-events: none;
}

.bouncing {
  animation: bounce 0.4s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
      transform: translate3d(0, -3px, 0);
  }
  to {
      transform: translate3d(0, 3px, 0);
  }
}

.pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
